body {
   background-color: #fff;
}
.header {
   font-weight: 700;
}

.footer {
   /* display: flex;
   justify-content: center; */
   align-items: center;
   text-align: center;
   width: 100%;
   border: "1px solid black";
}
.link {
   color: #333;
   text-decoration: none;
   font-family: Arial, Helvetica, sans-serif;
}
